.header {
  text-align: center;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

a {
  text-decoration: none;
}

/* Model Table */
.model-table {
  user-select: none;
}

/* Model Table Links  */
.model-table>tbody>tr {
  cursor: pointer;
}
